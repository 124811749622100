import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { Stack, Button, TableRow, MenuItem, TableCell, IconButton, Typography } from '@mui/material';
// components

import { onGetFullname } from '../../../../utils/general';

// ----------------------------------------------------------------------

UserProgramTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onSelectRow: PropTypes.func,
};

export default function UserProgramTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow, admin, show_nutritionist }) {
  const { id_user, email, employee_number, mobile, mobile_country_code, nutrio } = row;

  const userdata = {
    first_name:row?.user_first_name,
    fathers_last_name:row?.user_fathers_last_name,
    mothers_last_name:row?.user_mothers_last_name,
  }

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell sx={{cursor:'pointer', minWidth: 200, maxWidth:300}} onClick={onSelectRow}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle2">
              {onGetFullname(userdata)}
            </Typography>
          </Stack>
        </TableCell>
        {/*<TableCell sx={{cursor:'pointer'}} align="left" onClick={onSelectRow}>
          {row?.user_employee_number}
        </TableCell>*/}
        <TableCell sx={{cursor:'pointer'}} align="left" onClick={onSelectRow}>{row?.user_email ?? '-'}</TableCell>
        <TableCell sx={{cursor:'pointer'}} align="left" onClick={onSelectRow}>{row?.user_client_location ?? '-'}</TableCell>
        {/* <TableCell align="right">
          <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover} >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell> */}
      </TableRow>

      {/* <MenuPopover open={openPopover} onClose={handleClosePopover} arrow="right-top" sx={{ width: 140 }}>
        <MenuItem
          onClick={() => {
            handleOpenConfirm();
            handleClosePopover();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="eva:trash-2-outline" />
          Eliminar
        </MenuItem>

        
      </MenuPopover>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Eliminar usuario"
        content="¿Está seguro de eliminar este usuario? Ya no podrá recuperarse en un futuro"
        action={
          <ContainedButton size='small' onClick={onDeleteRow}>
            Eliminar
          </ContainedButton>
        }
      /> */}
    </>
  );
}
