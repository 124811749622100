import { Box, Card, Divider, Grid, Stack, Typography } from "@mui/material";
import { blueGrey } from "@mui/material/colors";

const ExecutivePatientInformation = ({
    total_patients=0,
    attending_patients=0

}) => {
    return ( 
        <Box>
            <Typography variant='h6' gutterBottom>Información de pacientes</Typography>
            <Card sx={{p:2, border:`1px solid ${blueGrey[300]}`}}>
                <Stack spacing={2}>
                    <Grid container>
                        <Grid item xs={12} md={8}>
                            <Typography >
                                Total de colaboradores canalizados al
                                programa
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Stack alignItems='flex-end'>
                                <Typography align='right' sx={{
                                    background: theme => theme.palette.primary.main,
                                    px:2, color:'white',
                                    borderRadius:1
                                }}>
                                    {total_patients}
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid container>
                        <Grid item xs={12} md={8}>
                            <Typography >
                                Colaboradores en atención
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Stack alignItems='flex-end'>
                                <Typography align='right' sx={{
                                    background: theme => theme.palette.primary.main,
                                    px:2, color:'white',
                                    borderRadius:1
                                }}>
                                    {attending_patients}
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Stack>
            </Card>
        </Box>
     );
}
 
export default ExecutivePatientInformation;