import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Box } from '@mui/material';
import PageLayout from '../../../layouts/PageLayout';
import NavigationTab from '../../../components/actions/NavigationTab';
import Iconify from '../../../components/iconify';
import useInitState from '../../../hooks/useInitState';
import PatientsBusinessProgramSection from './PatientsBusinessProgramSection/PatientsBusinessProgramSection';
import AnalyticsBusinessPrograSection from './AnalyticsBusinessPrograSection/AnalyticsBusinessPrograSection';
import { useAuthContext } from '../../../auth/useAuthContext';


const ProgramPage = () => {

    const {user} = useAuthContext()
    const {idclientprogram} = useParams()
    //console.log(idclientprogram)


    const {
        loading
    } = useInitState()

    const [currentTab, setCurrentTab] = useState('patients')
    const [clientID, setClientID] = useState()
    const [userID, setUserID] = useState()
    

    

    const initialize = useCallback(async() => {
      
        if(!user) return

        setClientID(user.client_id)
        setUserID(user.id_user)
        /* loading.onTrue()
        try {
            const _data = await clientManager.actions.onGet(user.client_id)
            console.log(_data)
            setClient(_data)
            const _program = await clientProgramManager.actions.getClientProgram(idclientprogram)
            setProgram(_program)
        } catch (error) {
            console.log(error)
        }
        loading.onFalse() */

    },[user, idclientprogram])

    useEffect(() => {
        initialize()
    },[user, idclientprogram])

    const TABS = [
        {
            value: 'patients',
            label: 'Usuarios',
            icon: <Iconify icon="fa:group" />,
            component: <PatientsBusinessProgramSection idclient={clientID} iduser={userID} idclientprogram={idclientprogram} />,
        },
        /* {
            value: 'appointments',
            label: 'Mapa de consultas',
            icon: <Iconify icon="streamline:waiting-appointments-calendar-solid" />,
            component: <AppointmentsProgramSection idclient={idclient} idclientprogram={idclientprogram} />,
        }, */
        {
            value:'analitics',
            label:'Analíticos',
            icon: <Iconify icon="simple-icons:googleanalytics" />,
            component: <AnalyticsBusinessPrograSection idclient={clientID} idclientprogram={idclientprogram} />
        }
    ]
    return ( 
        <PageLayout stretch={true}
            title='Detalles del programa'
            title_seo='Detalle del programa | Velfare'
            enable_return
            loading={loading.value}
            /* links={[
                {name:'Dashboard', href:PATH_DASHBOARD.general.app},
                {name:'Negocios', href:PATH_DASHBOARD.general.clients},
                {name:'Cuenta', },
            ]} */
        >
            <div>
                {/* <Card sx={{
                    borderRadius:1,
                    px:2, py:2,
                    background: theme => alpha(theme.palette.primary.main, 0.2)
                }}>
                    
                    <Grid container wrap='nowrap' spacing={1} alignItems={'center'}>
                        
                        <Grid item >
                            <CustomAvatar src={client?.image} alt={program?.program} name={program?.program} />
                        </Grid>
                        <Grid item>
                            <Typography variant='h6'>
                                Programa {program?.program}
                            </Typography>
                            <Typography variant='subtitle2' color='text.secondary'> 
                                {client?.name}
                            </Typography>
                        </Grid>
                    </Grid>
                </Card> */}
                <div>
                        <NavigationTab options={TABS} value={currentTab} onChange={(value) => setCurrentTab(value)}/>
                        {TABS.map(
                        (tab) =>
                            tab.value === currentTab && (
                                <Box key={tab.value} sx={{ mt: 2 }}>
                                    {tab.component}
                                </Box>
                            )
                        )}
                </div>
            </div>
        </PageLayout>
     );
}
 
export default ProgramPage;