import { useCallback, useEffect, useState } from "react";
import useInitState from "../../../../hooks/useInitState";
import useTableSettings from "../../../../hooks/useTableSettings";
import { useBoolean } from "../../../../hooks/useBoolean";
import { getAllClientProgramUsers } from "../../../../services/clientProgramService";

const usePatientBusinessProgramSection = ({
    idclient,
    idclientprogram,
    iduser
}) => {

    const {
        loading
    } = useInitState()

  
    const add_modal = useBoolean(false)

    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [search, setSearch] = useState("")
    const tableController = useTableSettings({
        initial_params:{
            order_by:JSON.stringify(['user_first_name']),
            order:0,
            limit:100, 
            offset:0,
        }
    })

    const onRequestSearch = useCallback((value) => {
        //console.log(value)
        setSearch(value)
        tableController.onUpdatePaginationValue('offset', 0)
    }, [tableController])


    const onGetParams = useCallback(() => {
        let result = {...tableController.pagination}
        result.offset = tableController.pagination.offset * tableController.pagination.limit
        //let ANDargs = [{field:'client_program_id', operator:"=", value:idclientprogram}]
        let ANDargs = []
        if(search){
            //console.log(search)
            /* console.log(search)
            let OR_array = [
                {field:'user_first_name', operator:"=", value:`%${search}%`},
                {field:'user_fathers_last_name', operator:"=", value:`%${search}%`},
                {field:'user_mothers_last_name', operator:"=", value:`%${search}%`},
            ]
            ANDargs.push({"OR":OR_array}) */
            ANDargs.push({
                "OR":[
                    {field:'user_first_name', operator:"LIKE", value:`%${search}%`},
                    {field:'user_fathers_last_name', operator:"LIKE", value:`%${search}%`},
                    {field:'user_mothers_last_name', operator:"LIKE", value:`%${search}%`},
                ]
            })
        }
        if(ANDargs.length){
            const temp = `${JSON.stringify({"AND":ANDargs})}`
            //console.log(JSON.parse(temp))
            result.filter = temp
        }
        return result
    }, [tableController, search, idclientprogram])

    const onGetPatients = useCallback(async() => {

        const params = onGetParams()
        console.log(params)
        const _request = await getAllClientProgramUsers(idclientprogram, params)
        console.log(_request)
        setData(_request.data)
        setTotal(_request.count)

    }, [onGetParams])

    const initialize = useCallback(async() => {
        loading.onTrue()
        try {
            await onGetPatients()
        } catch (e) {
            console.log(e)
        }

        loading.onFalse()
    }, [loading, onGetPatients])

    useEffect(() => {
        initialize()
    }, [search, tableController.pagination])

    return {
        loading,
        add_modal,
        data, 
        total,
        tableController,
        onRequestSearch,
        onGetPatients
    };
}
 
export default usePatientBusinessProgramSection;