import { private_server } from "../utils/axios"

/* export const getClient = async(idclient) => {
    let result = null
    const request = await private_server.get(`/client/${idclient}`)
    result = request.data.data.client
    return result
} */


export const getClientLocations = async(params) => {
    let result = {
        data:[], 
        count:0
    }
    const request = await private_server.get('/clientlocation/all/client', {params:params})
    result.data = request.data.data?.client_locations??[]
    result.count = request.data.data.count
    return result
}