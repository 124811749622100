import React from 'react';
import { 
    Card,
    Button,
    Grid,
    Typography,
    Avatar,
    Stack,
} from '@mui/material';
import { useNavigate } from 'react-router';
import useClientProgramsSection from './useClientProgramsSection';
import { PATH_DASHBOARD } from '../../../routes/paths';
import PageLayout from '../../../layouts/PageLayout';
import EmptyContent from '../../../components/empty-content';




const ClientProgramsSection = () => {

    const { data, system} = useClientProgramsSection()

    const { client_programs} = data
    const navigate = useNavigate();



    return ( 
        <PageLayout
            title='Programas activos'
            title_seo='Programas | Velfare'
            loading={system.sending}
            
        >
            <div>  
                <Typography sx={{mb:3}}>
                    En esta sección podrá encontrar los programas activos para sus colaboradores.
                </Typography>
                <Grid container spacing={3}>
                    {client_programs.map(item => {
                        return(
                            <Grid item xs={12} md={6} key={item?.id_client_program}>
                                <Card  sx={{
                                    px:2, py:3,
                                    border:theme => `1px solid ${theme.palette.primary.main}`
                                }}>
                                    <Stack alignItems='center'>
                                        <Avatar sx={{
                                                color:'white',
                                                background: theme => theme.palette.primary.main,
                                                fontWeight:800,
                                                width:80,
                                                height:80,
                                                fontSize:'2rem'
                                            }}>
                                                {item?.program?.charAt(0).toUpperCase()}
                                        </Avatar>
                                        <Typography variant='subtitle1' sx={{mb:2}}>
                                            {item?.name}
                                        </Typography>
                                        <Grid container justifyContent='flex-end' spacing={1}>
                                            <Grid item xs={12} md={12}>
                                                <Button fullWidth variant='outlined'
                                                    onClick={() => navigate(PATH_DASHBOARD.programs.view(item?.id_client_program))}
                                                >
                                                    Ver detalles
                                                </Button>
                                            </Grid>
                                            {/* <Grid item md={12}>
                                                <Button fullWidth variant='text' sx={{
                                                    color:blueGrey[300],
                                                    '&:hover':{
                                                        background:alpha(blueGrey[100],0.3),
                                                        color:blueGrey[300],
                                                    }
                                                }}>
                                                    <Iconify icon="eva:trash-2-outline" />
                                                    Eliminar
                                                </Button>
                                            </Grid> */}
                                        </Grid>
                                       
                                    </Stack>
                                </Card>
                            </Grid>      
                        )
                    })}
                </Grid>
                
                {client_programs.length === 0 && <EmptyContent title={"Sin registros"} description="No se encontraron programas dados de alta"/>}
                
            </div>
        </PageLayout>
     );
}
 
export default ClientProgramsSection;