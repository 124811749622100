import { private_server } from "../utils/axios"

export const getGlobalPatientCount = async(params) => {
    const request = await private_server.get('/analytic/client/globalpatientcount', {params:params})
    return request.data.data.global_patient_count
}


export const getGlobalAppointmentCount = async(params) => {

    let result = {
        data:[], 
        count:0
    }
    const request = await private_server.get('/analytic/client/globalappointmentcount', {params:params})
    //console.log(request.data.data.global_appointment_count)
     
    return request.data.data.global_appointment_count
}

export const getLocationPatientCount = async(params) => {

    const request = await private_server.get('/analytic/client/locationpatientcount', {params:params})
    return request.data.data.location_patient_count
}


export const getUsersByObjective = async(params) => {

    const request = await private_server.get('/analytic/client/usersbyobjective', {params:params})
    //console.log(request.data.data.users_by_objective)
    return request.data.data.users_by_objective
}

export const getAnalyticsAvergaMetrics = async(params) => {

    const request = await private_server.get('/analytic/client/averagemetrics', {params:params})
    if(request.data.data.average_metrics && request.data.data.average_metrics?.length > 0){
        return request.data.data.average_metrics[0]
    }
    
}


export const getAnalyticsRiskGroupMetrics = async(params) => {

    const request = await private_server.get('/analytic/client/usersbyrisklevel', {params:params})
    //console.log(request.data.data.users_by_risk_level)
    return request.data.data.users_by_risk_level
    //if(request.data.data.average_metrics && request.data.data.average_metrics?.length > 0){
    //    //return request.data.data.average_metrics[0]
    //}
    
}

export const getAnalyticsPathologiesMetrics = async(params) => {

    const request = await private_server.get('/analytic/client/usersbypathology', {params:params})
    //console.log(request.data.data.users_by_pathology)
    if(request.data.data.users_by_pathology && request.data.data.users_by_pathology?.length > 0){
        return request.data.data.users_by_pathology[0]
    }
    
}


export const getAnalyticsIMCMetrics = async(params) => {

    const request = await private_server.get('/analytic/client/usersbyimcdiagnostic', {params:params})
    return request.data.data.users_by_imc_diagnostic
}

export const getAnalyticsICCMetrics = async(params) => {

    const request = await private_server.get('/analytic/client/usersbyiccdiagnostic', {params:params})
    return request.data.data.users_by_icc_diagnostic
}


export const getAnalyticsDiagnosisMetrics = async(params) => {

    const request = await private_server.get('/analytic/client/usersbydigestionstatus', {params:params})
    console.log("diagnosis-----------")
    console.log(request.data.data)
    //if(request.data.data.users_by_pathology && request.data.data.users_by_pathology?.length > 0){
    //    return request.data.data.users_by_pathology[0]
    //}
    //
}

export const getAnalyticsObjetiveExtendedMetrics = async(params) => {

    const request = await private_server.get('/analytic/client/usersbyobjectiveextended', {params:params})
    //console.log(request.data.data.users_by_objective_extended)
    return request.data.data.users_by_objective_extended
    //if(request.data.data.users_by_pathology && request.data.data.users_by_pathology?.length > 0){
    //    return request.data.data.users_by_pathology[0]
    //}
    //
}



export const getAnalyticsLocationAverageMetrics = async(params) => {

    const request = await private_server.get('/analytic/client/locationaveragemetrics', {params:params})
    //console.log(request.data.data)
    return request.data.data.location_average_metrics
    //return request.data.data.users_by_icc_diagnostic
}

