import React from 'react'
import usePatientBusinessProgramSection from './usePatientBusinessProgramSection';
import { Box, Button, Card, Grid, LinearProgress, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import TableActionBar from '../../../../components/table/TableActionBar';
import TableHeadCustom from '../../../../components/table/TableHeadCustom';
import UserProgramTableRow from './UserProgramTableRow';
import TablePaginationCustom from '../../../../components/table/TablePaginationCustom';
import EmptyContent from '../../../../components/empty-content';


const tableHeader = [
    { id: 'name', label: 'Nombre', align: 'left' },
    { id: 'email', label: 'Email', align: 'left' },
    { id: 'client_location', label: 'Sitio', align: 'left' },
    { id: '', label: '', align: '' },
]

const PatientsBusinessProgramSection = ({
    idclient,
    idclientprogram,
    iduser
}) => {

    const {
        add_modal,
        loading,
        data,
        total,
        tableController,
        onRequestSearch,
        onGetPatients
    } = usePatientBusinessProgramSection({idclient, idclientprogram, iduser})

    const {pagination} = tableController

    return ( 
        <div>
            <Stack spacing={2}>
                <Typography>
                    En esta sección encontrará a los usuarios dados de alta en el programa.
                </Typography>
                <Grid container alignItems='center' >
                    <Grid item xs>
                        <TableActionBar onRequestSearch={onRequestSearch}/>
                    </Grid>
                    {/* <Grid item>
                        <Button variant='outlined' sx={{ml:2}} onClick={() => add_modal.onTrue()}>
                            Agregar
                        </Button>
                    </Grid> */}
                </Grid>
                {(loading.value) && <Box sx={{marginBottom:2}}><LinearProgress /></Box> }
                <Card sx={{overflow:'auto'}}>
                    <TableContainer sx={{position: 'relative', overflow: 'unset'  }}>
                        
                        <Table stickyHeader size={'small'} sx={{ minWidth: 800}}>
                            <TableHeadCustom
                                headLabel={tableHeader}
                            />
                            <TableBody>
                                {data.map((row) => (
                                    <UserProgramTableRow
                                        key={`user-item-${row?.id_user_client_program}`} 
                                        row={row}
                                    />
                                ))}
                                {data.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={5}>
                                            <EmptyContent title={"Sin información"} description="No se encontraron registros relacionadas a su búsqueda" />
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
                <Box sx={{marginTop:2}}>
                    <TablePaginationCustom    
                        tableFilter={pagination}               
                        total={total}
                        onChangePagination={(_data) => tableController.setPagination(_data)}
                    />
                </Box>
            </Stack>
            {/* <AddUser2ClientProgramModal 
                open={add_modal.value}
                onClose={() => add_modal.onFalse()}
                idclient={idclient}
                idclientprogram={idclientprogram}
                userclientprograms={data}
                onRefresh={onGetPatients}
            /> */}
        </div>
     );
}
 
export default PatientsBusinessProgramSection;