import { Box,  Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import useInitState from '../../../hooks/useInitState';
import { onGetAnalyticsRangeDates } from '../../../utils/general';
import LoadingContainer from '../../loaders/LoadingContainer';
import { getAnalyticsObjetiveExtendedMetrics } from '../../../services/analyticsService';
import Chart, { useChart } from '../../chart';
import { blue, blueGrey, indigo } from '@mui/material/colors';
import { catalogs } from '../../../utils/catalogs';
import { useBoolean } from '../../../hooks/useBoolean';
import { GENDERS } from '../../../utils/enums';

const GlobalAnthropometricChart = ({
    idclient, 
    idclientprogram, 
    period
}) => {

    const {
        loading
    } = useInitState()

    const loadingMale = useBoolean()
    const loadingFemale = useBoolean()
    const [imc_data, setIMCData] = useState([
        {
            name:'Anterior',
            group:'previous',
            data:[5,10,5,6,10,3],
        },
        {
            name:'Actual',
            group:'current',
            data:[4,12,4,1,3,6],
        },
    ])

    const [fatM_data, setFatMData] = useState([
        {
            name:'Anterior',
            group:'previous',
            data:[2,3,2,1,4,2],
        },
        {
            name:'Actual',
            group:'current',
            data:[2,8,2,3,6,1],
        },
    ])
    const [fatF_data, setFatFData] = useState([
        {
            name:'Anterior',
            group:'previous',
            data:[1,1,2,4,4,2],
        },
        {
            name:'Actual',
            group:'current',
            data:[3,4,2,3,1,3],
        },
    ])

    const initialize = useCallback(async() => {
       
        const dates = onGetAnalyticsRangeDates(period)

        loading.onTrue()

        try {
        
            // Current
            const _pathologiespdata_current = await getAnalyticsObjetiveExtendedMetrics({
                start_date:dates.current.start_date,
                end_date:dates.current.end_date,
                //client_id:idclient,
                //client_program_id:idclientprogram
            })

            // Current
            const _pathologiespdata_previous = await getAnalyticsObjetiveExtendedMetrics({
                start_date:dates.previous.start_date,
                end_date:dates.previous.end_date,
                //client_id:idclient,
                //client_program_id:idclientprogram
            })
            //console.log("Anterior")
            //console.log(_pathologiespdata_previous)

            let imc_previous = []
            let imc_current = []

            for(let i=0; i<catalogs.objectives.length; i++){
                const temp = catalogs.objectives[i]
                const value_prev = _pathologiespdata_previous.find(el=> el.objective_id === temp.value)
                const value_current = _pathologiespdata_current.find(el=> el.objective_id === temp.value)
                if(value_current){
                    //console.log("Se encontró current")
                    imc_current.push(Number(value_current?.imc_average ?? 0))
                }else{
                    imc_current.push(0)
                }
                if(value_prev){
                    //console.log("Se encontró prev")
                    imc_previous.push(Number(value_prev?.imc_average ?? 0))
                }else{
                    imc_previous.push(0)
                }

            }

            setIMCData([
                {
                    name:'Anterior',
                    group:'previous',
                    data:imc_previous,
                },
                {
                    name:'Actual',
                    group:'current',
                    data:imc_current,
                },
            ]) 

            //setPathologies(_pathologiespdata)
            //setGlobalPatient(_patientdata)
            
        } catch (error) {
            console.log(error)
        }

        loading.onFalse()

    },[loading, period])

    const initializebymale = useCallback(async() => {
       
        const dates = onGetAnalyticsRangeDates(period)

        loadingMale.onTrue()

        try {
        
            // Current
            const _pathologiespdata_current = await getAnalyticsObjetiveExtendedMetrics({
                start_date:dates.current.start_date,
                end_date:dates.current.end_date,
                //client_id:idclient,
                //client_program_id:idclientprogram,
                gender_id:GENDERS.MALE

            })

            // Current
            const _pathologiespdata_previous = await getAnalyticsObjetiveExtendedMetrics({
                start_date:dates.previous.start_date,
                end_date:dates.previous.end_date,
                //client_id:idclient,
                //client_program_id:idclientprogram,
                gender_id:GENDERS.MALE

            })

            let fat_previous = []
            let fat_current = []

            for(let i=0; i<catalogs.objectives.length; i++){
                const temp = catalogs.objectives[i]
                const value_prev = _pathologiespdata_previous.find(el=> el.objective_id === temp.value)
                const value_current = _pathologiespdata_current.find(el=> el.objective_id === temp.value)
                if(value_current){
                    fat_current.push(Number(value_current?.fat_mass_average ?? 0))
                }else{
                    fat_current.push(0)
                }
                if(value_prev){
                    //console.log("Se encontró prev")
                    fat_previous.push(Number(value_prev?.fat_mass_average ?? 0))
                }else{
                    fat_previous.push(0)
                }

            }

            setFatMData([
                {
                    name:'Anterior',
                    group:'previous',
                    data:fat_previous,
                },
                {
                    name:'Actual',
                    group:'current',
                    data:fat_current,
                },
            ]) 
            
        } catch (error) {
            console.log(error)
        }

        loadingMale.onFalse()

    },[loading, period])

    const initializebyfemale = useCallback(async() => {
        
        const dates = onGetAnalyticsRangeDates(period)

        loadingFemale.onTrue()

        try {
        
            // Current
            const _pathologiespdata_current = await getAnalyticsObjetiveExtendedMetrics({
                start_date:dates.current.start_date,
                end_date:dates.current.end_date,
                //client_id:idclient,
                client_program_id:idclientprogram,
                gender_id:GENDERS.FEMALE

            })

            // Current
            const _pathologiespdata_previous = await getAnalyticsObjetiveExtendedMetrics({
                start_date:dates.previous.start_date,
                end_date:dates.previous.end_date,
                //client_id:idclient,
                client_program_id:idclientprogram,
                gender_id:GENDERS.FEMALE

            })

            let fat_previous = []
            let fat_current = []

            for(let i=0; i<catalogs.objectives.length; i++){
                const temp = catalogs.objectives[i]
                const value_prev = _pathologiespdata_previous.find(el=> el.objective_id === temp.value)
                const value_current = _pathologiespdata_current.find(el=> el.objective_id === temp.value)
                if(value_current){
                    fat_current.push(Number(value_current?.fat_mass_average ?? 0))
                }else{
                    fat_current.push(0)
                }
                if(value_prev){
                    //console.log("Se encontró prev")
                    fat_previous.push(Number(value_prev?.fat_mass_average ?? 0))
                }else{
                    fat_previous.push(0)
                }

            }

            setFatFData([
                {
                    name:'Anterior',
                    group:'previous',
                    data:fat_previous,
                },
                {
                    name:'Actual',
                    group:'current',
                    data:fat_current,
                },
            ]) 
            
        } catch (error) {
            console.log(error)
        }

        loadingFemale.onFalse()

    },[loading, period])

    useEffect(() => {
        initialize()
        initializebymale()
        initializebyfemale()
    }, [period])

    const chartOptions = useChart({

        colors:[blue[300], indigo[600] ],
        dataLabels:{
            enabled:false
        },
        xaxis: {
            categories: [
              'Control de peso',
              'Alimentación saludable',
              'Actividad física',
              'Grupo de riesgo',
              'Embarazo',
              'Lactancia'
            ]
        },
    })

    return ( 
        <Box>
            <Typography variant='h5' gutterBottom>
                Comparativo de datos antropométricos por género en colaboradores de seguimiento.
            </Typography>
            <LoadingContainer loading={loading.value}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <LoadingContainer loading={loading.value}>
                            <div>
                                <Typography variant='h6' sx={{fontWeight:500, py:3}} align='center'>
                                    IMC promedio mes actual vs mes anterior
                                </Typography>
                                <Box
                                    sx={{
                                        width:'100%',
                                        maxWidth:800,
                                        margin:'auto'
                                    }}
                                    
                                >
                                    <Chart
                                        type="bar" 
                                        series={imc_data} 
                                        options={chartOptions}
                                    />
                                </Box>
                                <InfoTable 
                                        head={["IMC", "Diagnóstico"]}
                                        data={[
                                            ["Bajo de peso","< 18.5"],
                                            ["Normo peso","18.5 - 24.9"],
                                            ["Sobrepeso","25 - 29.9"],
                                            ["Obesidad 1","30 - 34.9"],
                                            ["Obesidad 2","35 - 39.9"],
                                            ["Obesidad 3","≥ 40"],
                                        ]}
                                    />
                            </div>
                        </LoadingContainer>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack>
                            <LoadingContainer loading={loadingMale.value}>
                                <div>
                                    <Box sx={{py:3}}>
                                        <Typography variant='subtitle1' align='center' >
                                            Mujeres
                                        </Typography>
                                        <Typography variant='h6' sx={{fontWeight:500}} align='center'>
                                        % de grasa promedio mes actual vs mes anterior
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            width:'100%',
                                            maxWidth:800,
                                            margin:'auto'
                                        }}
                                        
                                    >
                                        <Chart
                                            type="bar" 
                                            series={fatF_data} 
                                            options={chartOptions}
                                        />
                                    </Box>
                                    
                                    <InfoTable 
                                        head={["% de grasa", "Diagnóstico"]}
                                        data={[
                                            ["Delgado","< 8.0%"],
                                            ["Óptimo","8.1 a 15.9%"],
                                            ["Ligero sobrepeso","16.0 a 20.9%"],
                                            ["Sobrepeso","21 a 24.9%"],
                                            ["Obesidad","≥ 25%"],
                                        ]}
                                    />
                                </div>
                            </LoadingContainer>
                            <LoadingContainer loading={loadingFemale.value}>
                                <div>
                                    <Box sx={{py:3}}>
                                        <Typography variant='subtitle1' align='center' >
                                            Hombres
                                        </Typography>
                                        <Typography variant='h6' sx={{fontWeight:500}} align='center'>
                                        % de grasa promedio mes actual vs mes anterior
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            width:'100%',
                                            maxWidth:800,
                                            margin:'auto'
                                        }}
                                        
                                    >
                                        <Chart
                                            type="bar" 
                                            series={fatM_data} 
                                            options={chartOptions}
                                        />
                                    </Box>
                                    <InfoTable 
                                        head={["% de grasa", "Diagnóstico"]}
                                        data={[
                                            ["Delgado","< 15.0%"],
                                            ["Óptimo","15.1 a 20.9%"],
                                            ["Ligero sobrepeso","21.0 a 25.9%"],
                                            ["Sobrepeso","26 a 31.9%"],
                                            ["Obesidad","≥ 32%"],
                                        ]}
                                    />
                                </div>
                            </LoadingContainer>
                        </Stack>
                    </Grid>
                </Grid>
            </LoadingContainer>
        </Box>
     );
}
 
export default GlobalAnthropometricChart;

const InfoTable = ({
    head,
    data
}) => {
    return(
        <Table>
            <TableHead >
                <TableRow>
                    {head.map(item => {
                        return(
                            <TableCell key={item} sx={{
                                background: indigo[700],
                                color:'white',
                                py:0,
                                fontSize:12
                            }}>
                                {item}
                            </TableCell>
                        )
                    })}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((row, key) => {
                    return(
                        <TableRow key={`rowitem-${key}`}>
                            {row.map((el, idx) => {
                                return(
                                    <TableCell key={`rowcolitem-${key}-${idx}`} sx={{
                                        py:0,
                                        border:`1px solid ${blueGrey[50]}`,
                                        fontSize:12
                                    }}>
                                        {el}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    )
                })}
                
            </TableBody>
        </Table>
    )
}