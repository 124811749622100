import { Navigate, useRoutes } from "react-router"
import AuthGuard from "../auth/AuthGuard"
import GuestGuard from "../auth/GuestGuard"
//import SignInLayout from "../layouts/Auth/SignInLayout/SignInLayout"
import CompactLayout from "../layouts/CompactLayout"
import DashboardLayout from "../layouts/dashboard/DashboardLayout"
import Page404 from "../layouts/Mantainance/Page404/Page404"
import CartListPage from "../pages/Carts/CartList/CartListPage"
import {Typography} from '@mui/material'
import ResetPasswordPage from "../pages/Auth/ResetPasswordPage/ResetPasswordPage"
import OrderListPage from "../pages/Orders/OrderList/OrderList"

import { 
    LoginPage, 
    UsersListPage, 
    UserAccountPage, 
    NutritionistListPage, 
    NutritionistAccountPage,
    PatientListPage,
    BusinessListPage,
    BusinessAccountPage,
    OfficesListPage,
    ProductsListPage,
    BlogsListPage,
    AddBlogPage, 
    OfficeAccountPage,
    ProductPage,
    RecipesListPage,
    RecipePage,
    PatientAccountPage,
    DietsListPage,
    SmaeDietPage,
    SimplifiedEquivalentsDietPage,
    MenusDietPage,
    ColumnsDietPage,
    OptionsDietPage,
    PatientAppointmentPage,
    EditBlogPage,
    EquivalentsListPage,
    AchievementsListPage,
    EquivalentSMAEPage,
    AppDashPage,
    PublicAppointmentPage
} from "./elements"
import EquivalentPage from "../pages/Equivalents/EquivalentPage/EquivalentPage"
import PasswordChangePage from "../pages/Auth/PasswordChangePage/PasswordChangePage"
import AnalyticsPage from "../pages/Analytics/AnalyticsPage/AnalyticsPage"
import ClientProgramsSection from "../pages/Programs/ProgramsListPage/ClientProgramsSection"
import ProgramPage from "../pages/Programs/ProgramsPage/ProgramPage"

const Router = () => {
    // Auth
    return useRoutes([
        {
            path:'appointmentlink/:idappointment',
            element: <PublicAppointmentPage />
        },
        // auth
        {
            path:'auth',
            children:[
                {
                    path:'login',
                    element: <GuestGuard>
                        <LoginPage />
                    </GuestGuard> 
                },
                {
                    path:'recover-password',
                    element: <ResetPasswordPage />
                },
                
                
            ]
        },
        {
            path:'passwordchange',
            element: <PasswordChangePage />
        },
        // Dashboard
        {
            path:'dash',
            element: <AuthGuard>
                <DashboardLayout />
            </AuthGuard> ,
            children:[
                {
                    path:'app',
                    element: <AppDashPage />
                },
                {
                    path:'users/profile',
                    element: <UserAccountPage profile />
                },
                {
                    path:'users/:iduser/account',
                    element: <UserAccountPage />
                },
                {
                    path:'users',
                    element: <UsersListPage />
                },
                {
                    path:'nutritionists/:iduser/account',
                    element: <NutritionistAccountPage />
                },
                {
                    path:'nutritionists',
                    element: <NutritionistListPage />
                },
                {
                    path:'patients/:iduser/account/appointment/:idappointment',
                    element: <PatientAppointmentPage />
                },
                {
                    path:'patients/:iduser/account',
                    element: <PatientAccountPage />
                },
                {
                    path:'patients',
                    element: <PatientListPage />
                },
                {
                    path:'programs',
                    element: <ClientProgramsSection />
                },
                {
                    path:'programs/:idclientprogram',
                    element: <ProgramPage />
                },
                {
                    path:'achievements',
                    element: <AchievementsListPage />
                },
                {
                    path:'businesses',
                    element: <BusinessListPage />
                },
                {
                    path:'businesses/:idclient/account',
                    element: <BusinessAccountPage />
                },
                {
                    path:'offices/:idoffice/account',
                    element: <OfficeAccountPage />
                },
                {
                    path:'offices',
                    element: <OfficesListPage />
                },
                {
                    path:'offices',
                    element: <OfficesListPage />
                },
                {
                    path:'products/:idproduct',
                    element: <ProductPage />
                },
                {
                    path:'products',
                    element: <ProductsListPage />
                },
                {
                    path:'equivalents/simple/:idproduct',
                    element: <EquivalentPage />
                },
                {
                    path:'equivalents/smae/:idproduct',
                    element: <EquivalentSMAEPage />
                },
                {
                    path:'equivalents/:idproduct',
                    element: <EquivalentPage />
                },
                {
                    path:'equivalents',
                    element: <EquivalentsListPage />
                },
                {
                    path:'recipes/:idrecipe',
                    element: <RecipePage />
                },
                {
                    path:'recipes',
                    element: <RecipesListPage />
                },
                {
                    path:'diets/options/:iddiet',
                    element: <OptionsDietPage />
                },
                {
                    path:'diets/columns/:iddiet',
                    element: <ColumnsDietPage />
                },
                {
                    path:'diets/menus/:iddiet',
                    element: <MenusDietPage />
                },
                {
                    path:'diets/simplifiedequivalents/:iddiet',
                    element: <SimplifiedEquivalentsDietPage />
                },
                {
                    path:'diets/smae/:iddiet',
                    element: <SmaeDietPage />
                },
                {
                    path:'diets',
                    element: <DietsListPage />
                },
                {
                    path:'orders',
                    element: <OrderListPage />
                },
                {
                    path:'blogs/:idblog', element: <EditBlogPage />
                },
                {
                    path:'blogs', element: <BlogsListPage />
                },
                {
                    path:'blogs/add', element: <AddBlogPage />
                },
                {
                    path:'analytics', element: <AnalyticsPage />
                },
            ]
        },
        // Generico
        {
            element: <CompactLayout />,
            children:[
                {
                    path:'404',
                    element: <Page404 />
                },
            ]
        },
        { path: '*', element: <Navigate to="/auth/login" replace /> },
    ])
}

export default Router