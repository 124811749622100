import { Box, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import  { onGetAnalyticsRangeDates } from '../../../utils/general';
import LoadingContainer from '../../loaders/LoadingContainer';
import { getAnalyticsIMCMetrics } from '../../../services/analyticsService';
import Chart, { useChart } from '../../chart';
import { blueGrey, indigo } from '@mui/material/colors';
import { catalogs } from '../../../utils/catalogs';
import { useBoolean } from '../../../hooks/useBoolean';
import { GENDERS } from '../../../utils/enums';

const GlobalAnthropometricPieChart = ({
    idclientprogram, 
    period
}) => {

    const loadingMale = useBoolean()
    const loadingFemale = useBoolean()
    const [female_data_current, setFemaleDataCurrent] = useState([5,2,6,10,1])
    const [female_data_previous, setFemaleDataPrevious] = useState([5,2,6,10,1])
    const [male_data_current, setMaleDataCurrent] = useState([5,2,6,10,1])
    const [male_data_previous, setMaleDataPrevious] = useState([5,2,6,10,1])

    const _colors = catalogs.imc_diagnostics.map(item => item.color)
    const _labels = catalogs.imc_diagnostics.map(item => item.label)


    const initializebyfemale = useCallback(async() => {
        
        const dates = onGetAnalyticsRangeDates(period)

        loadingFemale.onTrue()

        try {
        
            // Current
            const _pathologiespdata_current = await getAnalyticsIMCMetrics({
                start_date:dates.current.start_date,
                end_date:dates.current.end_date,
                //client_id:idclient,
                client_program_id:idclientprogram,
                gender_id:GENDERS.FEMALE

            })

            // Past
            const _pathologiespdata_previous = await getAnalyticsIMCMetrics({
                start_date:dates.previous.start_date,
                end_date:dates.previous.end_date,
                //client_id:idclient,
                client_program_id:idclientprogram,
                gender_id:GENDERS.FEMALE
            })

            let imc_previous = []
            let imc_current = []

            for(let i=0; i<catalogs.imc_diagnostics.length; i++){
                const temp = catalogs.imc_diagnostics[i]
                const value_prev = _pathologiespdata_previous.find(el=> el.imc_diagnostic_id === temp.value)
                const value_current = _pathologiespdata_current.find(el=> el.imc_diagnostic_id === temp.value)
                if(value_current){
                    imc_current.push(Number(value_current?.user_count ?? 0))
                }else{
                    imc_current.push(0)
                }
                if(value_prev){
                    //console.log("Se encontró prev")
                    imc_previous.push(Number(value_prev?.user_count ?? 0))
                }else{
                    imc_previous.push(0)
                }

            }

            setFemaleDataPrevious(imc_previous)
            setFemaleDataCurrent(imc_current)
            
        } catch (error) {
            console.log(error)
        }

        loadingFemale.onFalse()

    },[loadingFemale, period])

    const initializebymale = useCallback(async() => {
        
        const dates = onGetAnalyticsRangeDates(period)

        loadingMale.onTrue()

        try {
        
            // Current
            const _pathologiespdata_current = await getAnalyticsIMCMetrics({
                start_date:dates.current.start_date,
                end_date:dates.current.end_date,
                //client_id:idclient,
                client_program_id:idclientprogram,
                gender_id:GENDERS.MALE

            })

            // Past
            const _pathologiespdata_previous = await getAnalyticsIMCMetrics({
                start_date:dates.previous.start_date,
                end_date:dates.previous.end_date,
                //client_id:idclient,
                client_program_id:idclientprogram,
                gender_id:GENDERS.MALE
            })

            let imc_previous = []
            let imc_current = []

            for(let i=0; i<catalogs.imc_diagnostics.length; i++){
                const temp = catalogs.imc_diagnostics[i]
                const value_prev = _pathologiespdata_previous.find(el=> el.imc_diagnostic_id === temp.value)
                const value_current = _pathologiespdata_current.find(el=> el.imc_diagnostic_id === temp.value)
                if(value_current){
                    imc_current.push(Number(value_current?.user_count ?? 0))
                }else{
                    imc_current.push(0)
                }
                if(value_prev){
                    //console.log("Se encontró prev")
                    imc_previous.push(Number(value_prev?.user_count ?? 0))
                }else{
                    imc_previous.push(0)
                }

            }

            setMaleDataPrevious(imc_previous)
            setMaleDataCurrent(imc_current)
            
        } catch (error) {
            console.log(error)
        }

        loadingMale.onFalse()

    },[loadingFemale, period])

    useEffect(() => {
        initializebymale()
        initializebyfemale()
    }, [period])

    const chartOptions = useChart({

        colors:_colors,
        labels:_labels,
        legend: {
            position:'right'
        },
    })

    return ( 
        <Box>
            <Typography variant='h5' gutterBottom>
                Comparativo de datos antropométricos por género en colaboradores de seguimiento.
            </Typography>
            <Stack spacing={2}>
                <AnalyticGenderRow 
                    title={"Género masculino"}
                    chartOptions={chartOptions}
                    current_data={male_data_current}
                    previous_data={male_data_previous}
                    loading={loadingMale.value}
                />
                <AnalyticGenderRow 
                    title={"Género femenino"}
                    chartOptions={chartOptions}
                    current_data={female_data_current}
                    previous_data={female_data_previous}
                    loading={loadingFemale.value}
                />
                <Grid container justifyContent={'center'}>
                    <Grid item xs={12} md={4}>
                        <InfoTable 
                            head={["IMC", "Diagnóstico"]}
                            data={[
                                ["Bajo de peso","< 18.5"],
                                ["Normo peso","18.5 - 24.9"],
                                ["Sobrepeso","25 - 29.9"],
                                ["Obesidad 1","30 - 34.9"],
                                ["Obesidad 2","35 - 39.9"],
                                ["Obesidad 3","≥ 40"],
                            ]}
                        />
                    </Grid>
                </Grid>
            </Stack>
        </Box>
     );
}
 
export default GlobalAnthropometricPieChart;

const AnalyticGenderRow = ({
    title,
    previous_data,
    current_data,
    loading,
    chartOptions
}) => {

    

    return(
        <div>
            <Typography variant='h6' align='center'>
                {title}
            </Typography>
            <LoadingContainer loading={loading}>
                <div>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Typography variant='body1' align='center' gutterBottom>
                                Mes pasado
                            </Typography>
                            <Box sx={{p:2}}>
                                <Box
                                    sx={{
                                        width:'100%',
                                        maxWidth:800,
                                        margin:'auto'
                                    }}
                                    
                                >
                                    <Chart
                                        type="donut" 
                                        series={previous_data} 
                                        options={chartOptions}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant='body1' align='center' gutterBottom>
                                Mes actual
                            </Typography>
                            <Box sx={{p:2}}>
                                <Box
                                    sx={{
                                        width:'100%',
                                        maxWidth:800,
                                        margin:'auto'
                                    }}
                                    
                                >
                                    <Chart
                                        type="donut" 
                                        series={current_data} 
                                        options={chartOptions}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            </LoadingContainer>
        </div>
    )
}

const InfoTable = ({
    head,
    data
}) => {
    return(
        <Table>
            <TableHead >
                <TableRow>
                    {head.map(item => {
                        return(
                            <TableCell key={item} sx={{
                                background: indigo[700],
                                color:'white',
                                py:0,
                                fontSize:12
                            }}>
                                {item}
                            </TableCell>
                        )
                    })}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((row, key) => {
                    return(
                        <TableRow key={`rowitem-${key}`}>
                            {row.map((el, idx) => {
                                return(
                                    <TableCell key={`rowcolitem-${key}-${idx}`} sx={{
                                        py:0,
                                        border:`1px solid ${blueGrey[50]}`,
                                        fontSize:12
                                    }}>
                                        {el}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    )
                })}
                
            </TableBody>
        </Table>
    )
}