import { useCallback, useEffect, useState } from "react"
import { useSnackbar } from "../../../components/snackbar";
import useTableSettings from "../../../hooks/useTableSettings";
import { getClientPrograms } from "../../../services/clientProgramService";


const useClientProgramsSection = () => {

    const { enqueueSnackbar } = useSnackbar();
    
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(false)
    const [client_programs, setClientPrograms] = useState([])
    //const [count, setCount] = useState(0)
    //const [selected, setSelected] = useState()

    const tableController = useTableSettings({
        /* initial_filter:{
            search:{key:'search', value:'', operator:'LIKE'},
        }, */
        initial_params:{
            order_by:'program',
            order:0,
            limit:10, 
            offset:0,
        }
    })

    /* const modalManager = useModal({
        add_client_location:false,
        edit_client_location:false,
    })


    const [modals, setModals] = useState({
        add_user:false,
        delete_user:false
    }) */

    useEffect(() => {
        actions.onInitModule()
    }, [])

    useEffect(() => {
        actions.onRefreshData()
    }, [tableController.pagination /* tableController.filter */])

    const onGetParams = useCallback(() => {
        let result = {...tableController.pagination}
        result.offset = tableController.pagination.offset * tableController.pagination.limit
        result.order_by = JSON.stringify([result.order_by])
        //let ANDargs = [{field:'client_id', operator:'=', value:idclient}]
        /* let ANDargs =[] 
        Object.keys(tableController.filter).forEach(key => {
            const item = tableController.filter[key]
            if(item.value && item.value !== -1){
                if(key === 'search'){
                    ANDargs.push({
                        "OR":[
                            {field:'name', operator:item.operator, value:`%${item.value}%`}
                        ]
                    })
                }else{
                    ANDargs.push({field:item.key, operator:item.operator, value:item.value})
                }
            }
            
        })

        if(ANDargs.length){
            const temp = `${JSON.stringify({"AND":ANDargs})}`
            //console.log(JSON.parse(temp))
            result.filter = temp
        } */
        
        return result
    },[tableController.pagination])

    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try {
                const params = onGetParams()
                //console.log(params)
                //const _clients_programs = await clientProgramManager.actions.onGetAll(params)
                //console.log(_clients_programs)
                const _clients_programs = await getClientPrograms(params)
                setClientPrograms(_clients_programs.data)
                //setCount(_clients_programs.count)
            } catch (error) {
                setError(error)
                enqueueSnackbar(error?.message, {variant:"error"})
            }
            setLoading(false)
        },
        onRefreshData: async() => {
            setSending(true)
            try {
                const params = onGetParams()
                const _clients_programs = await getClientPrograms(params)
                setClientPrograms(_clients_programs.data)
                //setCount(_clients_programs.count)
            } catch (error) {
                setError(error)
                enqueueSnackbar(error?.message, {variant:"error"})
            }
            setSending(false)
            
        },
        /* onRequestSearch: async(searchvalue) => {
            tableController.onUpdateFilterValue('search', searchvalue)
        },
        onSelectRow: (row) => {
            //console.log(row)
            setSelected(row)
            modalManager.onChangeModalStatus('edit_client_location', true)
            
        },
        onEditRow: (row) => {
            setSelected(row)
            actions.onChangeModalStatus('edit_user', true)
        },
        onDeleteRow: async(row) => {
            console.log('Eliminar', row)
            setSending(true)
            try {
                actions.onChangeModalStatus('delete_user', false)
                await clientProgramManager.actions.onDelete(row.id_client_program)
                await actions.onRefreshData()
                enqueueSnackbar('Sitio eliminada exitosamente', {variant:"success"})
            } catch (error) {
                console.log(error)
                setError(error)
                enqueueSnackbar(error?.message, {variant:"error"})
            }
            setSending(false)
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        }, 
        
        onChangePagination: (_data) =>{
            tableController.setPagination(_data)
            //setTableConfig(_data)
        } */
    }

    

    const system = {loading, sending, error}
    const data = {client_programs}

    return {
        system, data, /* actions, modals, tableController, modalManager, */ 
    }
}
 
export default useClientProgramsSection;