import { Box, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import Iconify from '../iconify';
import { onGetAnalyticsRangeDates } from '../../utils/general';
import { getAnalyticsPathologiesMetrics, getAnalyticsRiskGroupMetrics,  getGlobalPatientCount,  } from '../../services/analyticsService';
import LoadingContainer from '../loaders/LoadingContainer';
import useInitState from '../../hooks/useInitState';
import GlobalPyramidChart from './global/GlobalPyramidChart';
import GlobalRiskGroupChart from './global/GlobalRiskGroupChart';
import GlobalPathologiesChart from './global/GlobalPathologiesChart';
import GlobalAnthropometricChart from './global/GlobalAnthropometricChart';
import GlobalAnthropometricPieChart from './global/GlobalAnthropometricPieChart';
import GlobalIccPieChart from './global/GlobalIccPieChart';
import { catalogs } from '../../utils/catalogs';

const GlobalReport = ({
    idclient, 
    idclientprogram, 
    period,
    onReturn,
    
}) => {

    const {
        loading
    } = useInitState()

    const [global_patient, setGlobalPatient] = useState()
    const [pathologies, setPathologies] = useState()
    const [riskgroups, setRiskGroups] = useState([])

    const initialize = useCallback(async() => {
        
        const dates = onGetAnalyticsRangeDates(period)

        loading.onTrue()
        try {

            const _patientdata = await getGlobalPatientCount({
                start_date:dates.current.start_date,
                end_date:dates.current.end_date,
                //client_id:idclient,
                client_program_id:idclientprogram
            })
            setGlobalPatient(_patientdata)
            
        } catch (error) {
            console.log(error)
        }

        try {

            const _riskgroupdata = await getAnalyticsRiskGroupMetrics({
                start_date:dates.current.start_date,
                end_date:dates.current.end_date,
                //client_id:idclient,
                client_program_id:idclientprogram
            })
            console.log("Risk group data",_riskgroupdata)

            let _rkdata = []
            for(let i=0; i<catalogs.risk_levels.length; i++){
                const item = catalogs.risk_levels[i]
                console.log("Value", item.value)
                const el = _riskgroupdata.find(el => el.risk_level_id === item.value)
                _rkdata.push(Number(el?.user_count ?? 0))
            }
            setRiskGroups(_rkdata)
            
            
        } catch (error) {
            console.log(error)
        }

        
        try {

            const _pathologiespdata = await getAnalyticsPathologiesMetrics({
                //start_date:dates.current.start_date,
                //end_date:dates.current.end_date,
                //client_id:idclient,
                client_program_id:idclientprogram
            })
            console.log(_pathologiespdata)
            setPathologies(_pathologiespdata)
            
        } catch (error) {
            console.log(error)
        }
       
        loading.onFalse()
        console.log(loading.value)

    }, [period, idclient, idclientprogram])

    useEffect(() => {
        initialize()
    }, [period])


    return ( 
        <Box>
            <Stack direction='row'>
                <IconButton onClick={onReturn}>
                    <Iconify icon="material-symbols:arrow-back-rounded" />
                </IconButton>
                <Typography variant='h5'>
                    Datos Globales
                </Typography>
                
            </Stack>
            <Box sx={{py:3}}>
                {loading.value ? <LoadingContainer /> :
                (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={7}>
                                    <GlobalPyramidChart 
                                            total_users={global_patient?.total_users}
                                            initial_appointment_users={global_patient?.initial_appointment_users}
                                            follow_up_appointment_users={global_patient?.follow_up_appointment_users}
                                            scheduled_appointment_users={global_patient?.scheduled_appointment_users}
                                            pending_users={global_patient?.pending_users}
                                            disabled_users={global_patient?.disabled_users}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <GlobalRiskGroupChart 
                                    data={riskgroups}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <GlobalPathologiesChart 
                                    {...pathologies}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <GlobalAnthropometricChart 
                                    idclient={idclient}
                                    idclientprogram={idclientprogram}
                                    period={period}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <GlobalAnthropometricPieChart 
                                    idclient={idclient}
                                    idclientprogram={idclientprogram}
                                    period={period}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <GlobalIccPieChart 
                                    idclient={idclient}
                                    idclientprogram={idclientprogram}
                                    period={period}
                                />
                        </Grid>
                    </Grid>
                )}
            </Box>
            
        </Box>
     );
}
 
export default GlobalReport;